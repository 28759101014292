import 'slick-carousel';

function componentSlider($) {
	function initComponentSlider() {
		$('.component_slider__cards')
			.not('.slick-initialized')
			.slick({
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				gap: 16,
				dots: true,
				prevArrow: document.querySelector('.arrow.left'),
				nextArrow: document.querySelector('.arrow.right'),
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
							arrows: false,
						},
					},
				],
			});
	}

	$(document).ready(function () {
		initComponentSlider();
		$(window).on('resize', function () {
			initComponentSlider();
		});
	});
}

export default componentSlider;
