function flexibleTabs($) {
	if (document.querySelectorAll('.tab-container').length > 0) {
		$('.tab-container').each(function (i, tab) {
			if (i === 0) {
				$(this).addClass('open');
				$(this).next('.tab-content').fadeIn();
			}
		});

		$('.tab-container').on('click', '.tab-header', function () {
			var tabContainer = $(this).closest('.tab-container');
			var tabHeader = $(tabContainer).find('.tab-header');
			var tabContent = $(tabContainer).find('.tab-content');

			if ($('body').hasClass('page-template-template-membership')) {
				var tabInstance = $(this).data('tab');
				var tabClose = tabInstance == 0 ? 1 : 0;

				$('[data-membership="' + tabInstance + '"]').toggleClass('open');
				$('[data-membership="' + tabClose + '"]').removeClass('open');
			}

			if ($(this).hasClass('open')) {
				$(this).removeClass('open');
				$(this).next('.tab-content').hide();
			} else {
				$(tabHeader).removeClass('open');
				$(tabContent).hide();

				var $tab = $(this);
				var $content = $tab.next('.tab-content');

				$tab.toggleClass('open');
				$content.fadeToggle();
			}
		});
	}
}

export default flexibleTabs;
