const magnificPopup = ($) => {
	jQuery(document).ready(function ($) {
		$('.popup-video-link').magnificPopup({
			type: 'inline',
			midClick: true,
			preloader: false,
			fixedContentPos: true,
			callbacks: {
				open: function () {
					// Find the video element inside popup and play it
					var video = this.content.find('video')[0];
					if (video) {
						video.play();
					}
				},
				close: function () {
					// Pause video when popup is closed
					var video = this.content.find('video')[0];
					if (video) {
						video.pause();
						video.currentTime = 0; // Reset to beginning
					}
				},
			},
		});
	});
};

export default magnificPopup;
